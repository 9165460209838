/* eslint-disable no-console */
import {useCallback, useEffect} from 'react';
import {type CLSMetricWithAttribution, onCLS} from 'web-vitals/attribution';

import assertUnreachable from '~/utils/assertNever';

interface UseCLSDebuggingProps {
  config: {
    env: string;
    host: string;
  };
}

export function useCLSDebugging({config}: UseCLSDebuggingProps) {
  const consoleLogMetric = useCallback(
    (typedMetric: CLSMetricWithAttribution) => {
      if (config.env === 'production') {
        return;
      }
      const clsElement = (() => {
        try {
          return typedMetric.attribution.largestShiftTarget
            ? document.querySelector(typedMetric.attribution.largestShiftTarget)
            : '';
        } catch {
          return '';
        }
      })();
      const titleValueColor = (() => {
        switch (typedMetric.rating) {
          case 'good':
            return 'green';
          case 'needs-improvement':
            return 'yellow';
          case 'poor':
            return 'red';
          default:
            assertUnreachable(typedMetric.rating);
        }
      })();

      console.groupCollapsed(
        `%c Discovery-app:%c CLS %c${typedMetric.value}`,
        'font-size: 14px;font-weight: bold; color: #6758DC',
        'font-weight: bold',
        `color: ${titleValueColor}`,
      );

      console.log(
        `CLS rating: %c ${typedMetric.rating}`,
        `color: ${titleValueColor}`,
      );
      console.log('CLS value: ', typedMetric.value);
      console.log('CLS element: ', clsElement);

      console.log('CLS entries: ', typedMetric.entries);
      console.log('CLS attribution: ', typedMetric.attribution);

      console.groupEnd();
    },
    [config.env],
  );

  useEffect(() => {
    onCLS((metric) => {
      // TS cheat for proper typing since we are using onCLS from attribution package
      const typedMetric = metric as CLSMetricWithAttribution;

      consoleLogMetric(typedMetric);
    });
  }, [consoleLogMetric]);
}
