import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  json,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react';
import {AppProvider} from '@shopify/polaris-internal';
import polarisStyles from '@shopify/polaris-internal/build/esm/styles.css?url';
import {CurrencyCode, I18nContext} from '@shopify/react-i18n';
import {boundary} from '@shopify/shopify-app-remix/server';
import React, {useEffect, useRef} from 'react';

import {NotFound} from '~/components';
import {TrackPageView} from '~/foundation/App/components';
import {
  AppSetupContext,
  ShowError,
  SmartLink,
} from '~/foundation/AppSetupContext';
import {notify} from '~/foundation/AppSetupContext/context';
import {useI18nManager} from '~/foundation/AppSetupContext/hooks';
import {Main} from '~/foundation/Main';
import {Navigation} from '~/foundation/Navigation';
import {WebVitals} from '~/foundation/WebVitals';
import {PreviousRouteInfoProvider, useSessionStorage} from '~/hooks';
import {convertUnknownToError} from '~/utils/convertUnknownToError';

import {authenticate} from './shopify.server';

import type {HeadersFunction, LoaderFunctionArgs} from '@remix-run/node';

import {
  APP_ENV,
  NODE_ENV,
  REVISION,
  SHOPIFY_API_KEY,
  SPIN,
} from '~server/utils/env';

export const links = () => [{rel: 'stylesheet', href: polarisStyles}];

export const loader = async ({request}: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const isAuthPath = url.pathname.startsWith('/auth/login');
  if (!isAuthPath) {
    await authenticate.admin(request);
  }

  // Note: the query params exist when the app is launched, but not when you navigate to different pages.
  // Because of this, we need to put this data into a ref so that it persists across navigations.
  const searchParams = new URL(request.url).searchParams;
  const locale = searchParams.get('locale') ?? 'en-US';

  return json({
    data: {
      apiKey: SHOPIFY_API_KEY,
      host: searchParams.get('host') ?? '',
      env: NODE_ENV,
      appEnv: APP_ENV,
      revision: REVISION,
    },
    isSpin: Boolean(SPIN),
    isAuthPath,
    locale,
    lang: locale.split('-')[0],
  });
};

export default function App() {
  return <Outlet />;
}

export function Layout({children}: {children: React.ReactNode}) {
  const loaderData = useRouteLoaderData<typeof loader>('root');
  const {
    locale = 'en-US',
    lang = 'en',
    data = null,
    isSpin = false,
  } = useRef(loaderData).current ?? {};
  const {isAuthPath = false} = loaderData ?? {};

  return (
    <html lang={lang} dir="ltr">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {data ? <meta name="shopify-api-key" content={data.apiKey} /> : null}
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <Meta />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Links />
        <script src="https://cdn.shopify.com/shopifycloud/app-bridge.js" />
        {/* This is used for React devtools: https://github.com/Shopify/discovery-app/pull/4591 */}
        {isSpin ? <script async src="http://localhost:8097" /> : null}
      </head>
      <body>
        {isAuthPath || !data?.host ? (
          <AnonymousContext data={{locale}}>{children}</AnonymousContext>
        ) : (
          <AppSetupContext data={data}>
            <Navigation />
            <WebVitals config={data} />
            <PreviousRouteInfoProvider>
              <TrackPageView />
              <Main>{children}</Main>
            </PreviousRouteInfoProvider>
          </AppSetupContext>
        )}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
export function ErrorBoundary() {
  const routeError = useRouteError();
  const isRouteError = isRouteErrorResponse(routeError);
  useEffect(() => {
    if (isRouteError) {
      return;
    }
    const usableError = convertUnknownToError(routeError);
    if (usableError) {
      notify(usableError);
    }
  }, [isRouteError, routeError]);

  if (isRouteError && routeError.status === 404) {
    return <NotFound />;
  }

  return <ShowError />;
}

export const headers: HeadersFunction = (headersArgs) => {
  return boundary.headers(headersArgs);
};

function AnonymousContext({
  children,
  data,
}: {
  children: React.ReactNode;
  data: {locale: string};
}) {
  const [preferredLocale] = useSessionStorage('preferredLocale', '');
  const [preferredCurrencyCode] = useSessionStorage(
    'preferredCurrencyCode',
    '' as CurrencyCode,
  );
  const {i18nManager, i18n} = useI18nManager({
    locale: preferredLocale || data.locale,
    currencyCode: preferredCurrencyCode || 'USD',
  });

  return (
    <I18nContext.Provider value={i18nManager}>
      {i18n ? (
        <AppProvider i18n={i18n.translations} linkComponent={SmartLink}>
          {children}
        </AppProvider>
      ) : null}
    </I18nContext.Provider>
  );
}
