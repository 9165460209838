/* eslint-disable no-console */
import {useCallback, useEffect} from 'react';
import {type LCPMetricWithAttribution, onLCP} from 'web-vitals/attribution';

import assertUnreachable from '~/utils/assertNever';

interface UseLCPDebuggingProps {
  config: {
    env: string;
    host: string;
  };
}

export function useLCPDebugging({config}: UseLCPDebuggingProps) {
  const consoleLogMetric = useCallback(
    (typedMetric: LCPMetricWithAttribution) => {
      if (config.env === 'production') {
        return;
      }

      const lcpElement = (() => {
        try {
          return typedMetric.attribution.element
            ? document.querySelector(typedMetric.attribution.element)
            : '';
        } catch {
          // do nothing
        }
      })();
      const lcpSubPartMeasures = [
        {
          name: 'Time to first byte',
          value: typedMetric.attribution.timeToFirstByte,
        },
        {
          name: 'Resource load delay',
          value: typedMetric.attribution.resourceLoadDelay,
        },
        {
          name: 'Resource load time',
          value: typedMetric.attribution.resourceLoadDuration,
        },
        {
          name: 'Element render delay',
          value: typedMetric.attribution.elementRenderDelay,
        },
      ];

      const titleValueColor = (() => {
        switch (typedMetric.rating) {
          case 'good':
            return 'green';
          case 'needs-improvement':
            return 'yellow';
          case 'poor':
            return 'red';
          default:
            assertUnreachable(typedMetric.rating);
        }
      })();

      console.groupCollapsed(
        `%c Discovery-app:%c LCP %c${typedMetric.value}`,
        'font-size: 14px;font-weight: bold; color: #6758DC',
        'font-weight: bold',
        `color: ${titleValueColor}`,
      );
      console.log(
        `LCP rating: %c ${typedMetric.rating}`,
        `color: ${titleValueColor}`,
      );
      console.log('LCP value: ', typedMetric.value);
      console.log('LCP element: ', lcpElement);
      console.log('LCP entries: ', typedMetric.entries);
      console.table(
        lcpSubPartMeasures.map(({name, value}) => ({
          'LCP sub-part': name,
          'Time (ms)': value,
          '% of LCP': `${Math.round((1000 * value) / typedMetric.value) / 10}%`,
        })),
      );
      console.groupEnd();
    },
    [config.env],
  );

  useEffect(() => {
    onLCP((metric) => {
      // TS cheat for proper typing since we are using onLCP from attribution package
      const typedMetric = metric as LCPMetricWithAttribution;
      consoleLogMetric(typedMetric);
    });
  }, [consoleLogMetric]);
}
