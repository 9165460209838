import {matchPath, useNavigation} from '@remix-run/react';
import {Loading} from '@shopify/app-bridge-react';
import React from 'react';

import {FilterDetailsSkeleton} from '~/sections/Filters/FilterDetails/FilterDetailsSkeleton';
import {FiltersListSkeleton} from '~/sections/Filters/FiltersList/FiltersListSkeleton';
import {ProductBoostsDetailsSkeleton} from '~/sections/ProductBoosts/ProductBoostsDetails/ProductBoostsDetailsSkeleton';
import {ProductBoostsListSkeleton} from '~/sections/ProductBoosts/ProductBoostsList/ProductBoostsListSkeleton';
import {ProductRecommendationsDetailsSkeleton} from '~/sections/ProductRecommendations/ProductRecommendationsDetails/ProductRecommendationsDetailsSkeleton';
import {ProductRecommendationsListSkeleton} from '~/sections/ProductRecommendations/ProductRecommendationsList/ProductRecommendationsListSkeleton';
import {SynonymGroupDetailsSkeleton} from '~/sections/Synonyms/SynonymGroupDetails/SynonymGroupDetailsSkeleton';
import {SynonymGroupListSkeleton} from '~/sections/Synonyms/SynonymGroupList/SynonymGroupListSkeleton';
import {
  filtersURL,
  overviewURL,
  productBoostsURL,
  productRecommendationsURL,
  searchURL,
  settingsURL,
  synonymGroupURL,
} from '~/utils/urls';

import {useBugsnagContext} from '../AppSetupContext';

const pathConfig: {pathname: string; Component: React.FunctionComponent}[] = [
  {pathname: overviewURL(), Component: () => <Loading />},
  {pathname: searchURL(), Component: () => <Loading />},
  {pathname: settingsURL(), Component: () => <Loading />},
  {pathname: filtersURL(), Component: FiltersListSkeleton},
  {pathname: filtersURL(':id'), Component: FilterDetailsSkeleton},
  {pathname: productBoostsURL(), Component: ProductBoostsListSkeleton},
  {pathname: productBoostsURL(':id'), Component: ProductBoostsDetailsSkeleton},
  {pathname: synonymGroupURL(), Component: SynonymGroupListSkeleton},
  {pathname: synonymGroupURL(':id'), Component: SynonymGroupDetailsSkeleton},
  {
    pathname: productRecommendationsURL(),
    Component: ProductRecommendationsListSkeleton,
  },
  {
    pathname: productRecommendationsURL(':id'),
    Component: ProductRecommendationsDetailsSkeleton,
  },
];

export function Main({children}: {children: React.ReactNode}) {
  const {notify} = useBugsnagContext();
  const navigation = useNavigation();

  if (navigation.state !== 'loading') {
    return children;
  }

  const match = pathConfig.find(({pathname}) =>
    matchPath(pathname, navigation.location.pathname),
  );

  if (!match) {
    notify(
      `No match found for ${navigation.location.pathname}, defaulting to the Loading component`,
    );
    return <Loading />;
  }

  return <match.Component />;
}
