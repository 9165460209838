import {useLocation} from '@remix-run/react';
import {useEffect, useRef} from 'react';
import {onCLS, onINP, onLCP} from 'web-vitals';

import {useCLSDebugging, useLCPDebugging} from '~/foundation/WebVitals/hooks';
import {useAppBridgeStateStaffMember, useAuthenticatedFetch} from '~/hooks';
import {EXPECTED_LOCALES, type ExpectedLocalesType} from '~/utils/constants';

type MetricName = 'CLS' | 'INP' | 'LCP';

interface WebVitalsProps {
  config: {
    env: string;
    host: string;
  };
}

const STATSD_ENDPOINT = '/api/statsd';

export function WebVitals({config}: WebVitalsProps) {
  const {pathname} = useLocation();
  const staffMember = useAppBridgeStateStaffMember();
  const entryRoute = useRef(pathname.replace(/\/\d+$/, '/details'));
  const authenticatedFetch = useAuthenticatedFetch();

  useLCPDebugging({config});
  useCLSDebugging({config});

  const vitals = useRef<{
    [name in MetricName]: {
      name: name;
      value: number | null;
      reported: boolean;
    };
  }>({
    CLS: {name: 'CLS', reported: false, value: null},
    INP: {name: 'INP', reported: false, value: null},
    LCP: {name: 'LCP', reported: false, value: null},
  });

  useEffect(() => {
    const reporter = ({
      name,
      value,
    }: {
      name: MetricName;
      value: number | null;
    }) => {
      const vital = vitals.current[name];
      if (vital.reported || !staffMember) {
        return;
      }
      vital.value = value;
      vital.reported = true;

      const translatableLocale = (() => {
        if (
          EXPECTED_LOCALES.includes(staffMember.locale as ExpectedLocalesType)
        ) {
          return staffMember.locale;
        }
        const langNoRegion = staffMember.locale.split('-')[0];
        if (EXPECTED_LOCALES.includes(langNoRegion as ExpectedLocalesType)) {
          return langNoRegion;
        }

        return 'en';
      })();

      authenticatedFetch(STATSD_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify({
          stat: 'WebVitals',
          vital: vital.name,
          value: vital.value,
          tags: {
            route: entryRoute.current,
            shop_lang: translatableLocale,
            device_resolution: `${window.screen.width}x${window.screen.height}`,
            visual_viewport: visualViewport
              ? `${visualViewport.width}x${visualViewport.height}`
              : '',
          },
        }),
      });
    };

    onCLS(reporter);
    onINP(reporter);
    onLCP(reporter);
  }, [authenticatedFetch, staffMember]);

  return null;
}
