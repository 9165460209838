import React from 'react';

interface RenderChildrenOrThrowProps {
  error?: Error;
  children: React.ReactNode;
}

export function RenderChildrenOrThrow({
  error,
  children,
}: RenderChildrenOrThrowProps) {
  if (error) {
    throw error;
  }
  return <>{children}</>;
}
